<template>
  <div class="admin-dashboard">
    <h1>{{ $t("titles.dashboard") | capitalize }}</h1>

    <incident-table :initial-filters="{ status: 'in_process' }" />
    <community-users-list
      id="admin-dashboard-user-approvals"
      :fetch-params="{ status: 'new', for: 'admin' }"
      :hide-fields="['role', 'status']"
      :initial-filters="{
        updated_at: $dayjs().subtract(2, 'month').toISOString() + '@',
        proof_state: 'unevaluated',
      }"
      :sync-filters-with-url="false"
      :show-generate-csv="false"
      label="Participants à approuver"
    />
    <paginated-table
      v-if="isGlobalAdmin"
      id="admin-dashboard-borrower-approvals"
      endpoint="users"
      :fetch-params="{
        'borrower.submitted_at': '@',
        '!borrower.approved_at': '@',
      }"
      :show-generate-csv="false"
      label="Dossiers de conduite à approuver"
      :columns="userColumns"
      :extra-filters="userExtraFilters"
      :show-action-column="false"
      @row-clicked="(item) => $router.push(`/admin/users/${item.id}#borrower`)"
    />
    <!--    Users in need of approval of borrower data -->
  </div>
</template>

<script>
import CommunityUsersList from "@/components/Community/CommunityUsersList.vue";
import IncidentTable from "@/components/Incident/IncidentTable.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import { isGlobalAdmin } from "@/helpers/permissions/users";

export default {
  name: "AdminDashboard",
  components: {
    IncidentTable,
    CommunityUsersList,
    PaginatedTable,
  },
  data: function () {
    return {
      userExtraFilters: [
        new Filter("community_id", "Communauté", "relation", {
          relation: "communities",
          label: "name",
          field: "id",
          params: { for: "admin" },
        }),
      ],

      userColumns: [
        new Column("id", "ID", "id"),
        new Column("full_name", "Nom", "text"),
        new Column("email", "Courriel"),
        new Column("borrower.submitted_at", "Date de soumission", "date"),
      ],
    };
  },
  computed: {
    isGlobalAdmin() {
      return isGlobalAdmin(this.$store.state.user);
    },
  },
};
</script>

<style lang="scss"></style>
