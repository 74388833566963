<template>
  <info-box
    class="loan-info-box"
    :user-avatar="otherUser"
    :title="loan.loanable.name"
    :big-img="loan.loanable.image"
    :image-corner="isBorrower ? 'top-right' : 'bottom-right'"
    :link="`/loans/${loan.id}`"
    :loading="loading"
  >
    <b-row class="flex-fill">
      <b-col class="loan-info-box__name" cols="12" xl>
        <b-row>
          <b-col>
            <span v-if="otherUser" class="loan-info-box__name__user">
              {{ otherUser.full_name }}
              <br />
            </span>
            <span class="loan-info-box__name__loanable">{{ loan.loanable.name }}</span>
          </b-col>
        </b-row>

        <b-row class="mt-2 align-items-end">
          <b-col>
            <b-row>
              <b-col xl="auto" lg="12" class="loan-info-box__details">
                <span v-if="multipleDays">
                  {{ zonedTimes.departureAt | shortDate }} {{ zonedTimes.departureAt | time }} |
                  {{ zonedTimes.returnAt | date }} {{ zonedTimes.returnAt | time }}
                </span>
                <span v-else>
                  {{ zonedTimes.departureAt | shortDate }} | {{ zonedTimes.departureAt | time }} à
                  {{ zonedTimes.returnAt | time }}
                </span>
              </b-col>
              <b-col class="mt-2 mt-xl-0">
                <loan-status :item="loan"></loan-status>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="loan-info-box__buttons mt-2 mt-sm-0" cols="12" sm="auto">
            <icon-button
              v-if="hasButton('accept') && canAcceptLoan"
              size="sm"
              :disabled="loading"
              variant="outline-success"
              @click.prevent="acceptLoan"
            >
              Accepter
            </icon-button>
            <icon-button
              v-if="hasButton('reject') && canRejectLoan"
              size="sm"
              :disabled="loading"
              variant="outline-danger"
              @click.prevent="rejectLoan"
            >
              Refuser
            </icon-button>

            <icon-button
              v-if="hasButton('cancel') && canCancelLoan"
              size="sm"
              :disabled="loading"
              variant="outline-danger"
              icon="x-octagon"
              @click.prevent="cancelLoan"
            >
              Annuler
            </icon-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </info-box>
</template>
<script>
import LoanStatus from "@/components/Loan/Status/LoanStatusBadge.vue";
import IconButton from "@/components/shared/IconButton.vue";
import InfoBox from "@/components/shared/InfoBox.vue";
import { getZonedTimes } from "@/helpers/loanTimezones";
import { getOwner } from "@/helpers/permissions/loanables";
import {
  canAcceptLoan,
  canCancelLoan,
  canRejectLoan,
  canViewLoan,
  isBorrower,
} from "@/helpers/permissions/loans";
import { acceptLoan, cancelLoan, rejectLoan } from "@/requests/loanRequests";

export default {
  name: "LoanInfoBox",
  components: {
    IconButton,
    InfoBox,
    LoanStatus,
  },
  props: {
    buttons: {
      type: Array,
      required: false,
      default() {
        return ["accept", "reject"];
      },
    },
    loan: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    withSteps: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    zonedTimes() {
      return getZonedTimes(this.loan);
    },
    canCancelLoan() {
      return canCancelLoan(this.user, this.loan, this.$second);
    },
    isAvailable() {
      return this.$store.state.loans.item.isAvailable;
    },
    multipleDays() {
      return (
        this.zonedTimes.departureAt.format("YYYY-MM-DD") !==
        this.zonedTimes.returnAt.format("YYYY-MM-DD")
      );
    },
    otherUser() {
      if (this.isBorrower) {
        return this.getOwner(this.loan.loanable);
      }

      return this.loan.borrower_user;
    },
    canViewLoan() {
      return canViewLoan(this.user, this.loan);
    },
    canAcceptLoan() {
      return canAcceptLoan(this.user, this.loan);
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
    canRejectLoan() {
      return canRejectLoan(this.user, this.loan);
    },
  },
  methods: {
    getOwner,
    async acceptLoan() {
      this.loading = true;
      const acceptedLoan = await acceptLoan(this.loan);
      this.loading = false;

      if (acceptedLoan) {
        await this.$store.dispatch("dashboard/loadLoans");
        this.$emit("changed", this.loan.id);
      }
    },
    async cancelLoan() {
      try {
        this.loading = true;
        await cancelLoan(this.loan.id);
        await this.$store.dispatch("dashboard/loadLoans");
        this.$emit("changed", this.loan.id);
      } finally {
        this.loading = false;
      }
    },
    async rejectLoan() {
      try {
        this.loading = true;
        await rejectLoan(this.loan.id);
        await this.$store.dispatch("dashboard/loadLoans");
        this.$emit("changed", this.loan.id);
      } finally {
        this.loading = false;
      }
    },
    hasButton(name) {
      return this.buttons.indexOf(name) > -1;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.loan-info-box {
  &__name {
    color: $black;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  &__name__loanable {
    font-size: 1.25rem;
  }

  &__details {
    color: $black;
  }

  &__buttons {
    justify-self: end;
    > .btn + .btn {
      margin-left: 0.5rem;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    @include media-breakpoint-up(xl) {
      justify-content: end;
    }
  }
}
</style>
