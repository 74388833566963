<template>
  <div v-if="downloadUrl" class="d-inline">
    <a v-if="download" ref="link" :download="filename" :href="downloadUrl" target="_blank">
      <slot>{{ filename }}</slot>
    </a>
    <a v-else ref="link" :href="downloadUrl" target="_blank">
      <slot>{{ filename }}</slot>
    </a>
    <template v-if="downloadAsIcon">
      &nbsp;
      <a ref="downloadLink" :download="filename" :href="downloadUrl" target="_blank">
        <b-icon icon="download" />
      </a>
    </template>
  </div>
  <div v-else class="d-inline-block">
    <div class="d-inline">
      <icon-button v-if="buttonLabel" role="download" :onclick="downloadFile" v-bind="$attrs">
        {{ buttonLabel }}
      </icon-button>
      <b-link v-else :class="{ 'text-danger': failedLoading }" @click="() => downloadFile(false)">
        <slot>{{ filename }}</slot>
      </b-link>
      <template v-if="downloadAsIcon && !buttonLabel">
        &nbsp;
        <b-link :class="{ 'text-danger': failedLoading }" @click="() => downloadFile(true)">
          <b-icon icon="download" />
        </b-link>
      </template>
    </div>
    <b-progress v-if="percentCompleted > 0" height="2px" :value="percentCompleted" />
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import { get } from "@/requests/server";
import Vue from "vue";

export default Vue.extend({
  name: "FileDownloadLink",
  components: { IconButton },
  props: {
    filename: {
      type: String,
      required: true,
    },
    download: {
      type: Boolean,
      required: false,
      default: false,
    },
    downloadAsIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonLabel: {
      type: String,
      required: false,
      default: null,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    apiParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      downloadUrl: null,
      percentCompleted: 0,
      failedLoading: false,
    };
  },
  watch: {
    apiUrl() {
      if (this.downloadUrl) {
        URL.revokeObjectURL(this.downloadUrl);
        this.downloadUrl = null;
      }
      this.percentCompleted = 0;
      this.failedLoading = false;
    },
  },
  beforeDestroy() {
    if (this.downloadUrl) {
      URL.revokeObjectURL(this.downloadUrl);
      this.downloadUrl = null;
    }
  },
  methods: {
    async downloadFile(forceDownload = false) {
      if (!this.downloadUrl && !this.percentCompleted) {
        try {
          const response = await get(this.apiUrl, {
            axiosRequestConfig: {
              responseType: "blob",
              params: this.apiParams,
              onDownloadProgress: (progressEvent) => {
                this.percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                ); // you can use this to show user percentage of file downloaded
              },
            },
            notifications: {
              action: "chargement du fichier",
              ressourceName: this.filename,
            },
          });
          this.downloadUrl = URL.createObjectURL(response.data);
        } catch (e) {
          this.failedLoading = true;
        } finally {
          this.percentCompleted = 0;
        }
      }

      if (this.downloadUrl) {
        // Once we render the link, we click it rather than just opening the url in a new window
        // so we can use the <a>'s "download" attribute which sets the file name.
        this.$nextTick(() => {
          if (forceDownload) {
            this.$refs.downloadLink?.click();
          } else {
            this.$refs.link?.click();
          }
        });
      }
    },
  },
});
</script>
