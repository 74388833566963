<script>
import FormsImageUploader from "@/components/Forms/ImageUploader.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import LoanPageEditableBox from "@/components/Loan/LoanPageEditableBox.vue";
import ConditionalContext from "@/components/shared/ConditionalContext.vue";
import IconButton from "@/components/shared/IconButton.vue";
import SafeImage from "@/components/shared/SafeImage.vue";
import { integer } from "@/helpers/filters";
import { getOwner, isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { canChangeLoanInfo, isBorrower } from "@/helpers/permissions/loans";
import { put } from "@/requests/server";

export default {
  name: "LoanFactorsBox",
  components: {
    ConditionalContext,
    FormsImageUploader,
    FormsValidatedInput,
    IconButton,
    LoanPageEditableBox,
    SafeImage,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    borrowerInvoice: {
      type: Object,
      default: () => ({}),
    },
    ownerInvoice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      estimatedDistance: this.loan.estimated_distance,
      expenseImage: this.loan.expense_image,
      expenseImageLoading: false,
      expensesAmount: this.loan.expenses_amount,
      mileageEnd: this.loan.mileage_end,
      mileageEndImage: this.loan.mileage_end_image,
      mileageEndImageLoading: false,
      mileageStart: this.loan.mileage_start,
      mileageStartImage: this.loan.mileage_start_image,
      mileageStartImageLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    loanableOwnerUser() {
      return getOwner(this.loan.loanable);
    },
    isEditable() {
      if (!this.loan.borrower_must_pay_compensation) {
        return false;
      }

      return (
        this.canChangeLoanInfo &&
        ["accepted", "confirmed", "ongoing", "ended", "validated"].includes(this.loan.status)
      );
    },
    startsEditable() {
      if (!this.isEditable) {
        return false;
      }

      if (!this.needsMoreInformation) {
        return false;
      }

      if (this.loan.status === "confirmed") {
        return !this.loan.mileage_start;
      }

      return ["ongoing", "ended"].includes(this.loan.status);
    },
    canChangeLoanInfo() {
      return canChangeLoanInfo(this.$store.state.user, this.loan);
    },
    showReturnForm() {
      return ["ongoing", "ended", "validated"].includes(this.loan.status);
    },
    needsDetailedMileage() {
      return this.loan.requires_detailed_mileage;
    },
    needsExpenses() {
      return this.loan.can_add_expenses;
    },
    submitDisabled() {
      return (
        this.expenseImageLoading ||
        this.mileageStartImageLoading ||
        this.mileageEndImageLoading ||
        this.expensesAmount > this.maxExpenses
      );
    },
    mileageFilled() {
      return this.loan.mileage_start !== null && this.loan.mileage_end !== null;
    },
    needsMoreInformation() {
      return this.needsDetailedMileage && !this.mileageFilled;
    },
    needsValidation() {
      return (
        this.loan.status === "ended" && this.loan.needs_validation && !this.needsMoreInformation
      );
    },
    needsUserValidation() {
      return (
        (this.loan.borrower_user.id === this.user.id && this.needsBorrorwerValidation) ||
        (isCoownerOrOwner(this.user, this.loan.loanable) && this.needsOwnerValidation)
      );
    },

    needsOwnerValidation() {
      return this.needsValidation && !this.loan.owner_validated_at;
    },
    needsBorrorwerValidation() {
      // Or if user has modified tip!
      return this.needsValidation && !this.loan.borrower_validated_at;
    },
    changed() {
      return (
        this.estimatedDistance !== this.loan.estimated_distance ||
        this.expenseImage?.id !== this.loan.expense_image?.id ||
        this.expensesAmount !== this.loan.expenses_amount ||
        this.mileageEnd !== this.loan.mileage_end ||
        this.mileageEndImage?.id !== this.loan.mileage_end_image?.id ||
        this.mileageStart !== this.loan.mileage_start ||
        this.mileageStartImage?.id !== this.loan.mileage_start_image?.id
      );
    },
    maxExpenses() {
      let maxExpenses = 0;

      for (const item of this.borrowerInvoice?.items || this.ownerInvoice?.items || []) {
        if (item.item_type === "loan.price") {
          maxExpenses += Math.abs(item.total);
        }
      }
      return maxExpenses;
    },
    showDuration() {
      return (
        ["requested", "accepted", "confirmed"].includes(this.loan.status) || !this.needsExpenses
      );
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
  },
  methods: {
    integer,
    async save(close) {
      const { data } = await put(
        `/loans/${this.loan.id}/factors`,
        {
          estimated_distance: this.estimatedDistance,
          expense_image_id: this.expenseImage ? this.expenseImage.id : null,
          expenses_amount: this.expensesAmount || null,
          mileage_end: this.mileageEnd || null,
          mileage_end_image_id: this.mileageEndImage ? this.mileageEndImage.id : null,
          mileage_start: this.mileageStart || null, // replace empty strings with null
          mileage_start_image_id: this.mileageStartImage ? this.mileageStartImage.id : null,
        },
        {
          notifications: { action: "modification des informations de l'emprunt" },
        }
      );

      this.$emit("input", data);
      this.$emit("estimate"); // reset estimation, since updated loan has fresh invoices
      this.$nextTick(() => {
        // Only close if we're not keeping it open on purpose
        if (!this.startsEditable) {
          close();
        }
      });
    },
    async validate() {
      const { data } = await put(`/loans/${this.loan.id}/validate`, null, {
        notifications: { action: "validation de l'emprunt" },
      });
      this.$emit("input", data);
      this.$emit("estimate"); // reset estimation, since updated loan has fresh invoices
    },
    estimate() {
      this.$emit("estimate", {
        estimatedDistance: this.estimatedDistance,
        expensesAmount: this.expensesAmount,
        mileageEnd: this.mileageEnd,
        mileageStart: this.mileageStart,
      });
    },
    reset() {
      this.estimatedDistance = this.loan.estimated_distance;
      this.expenseImage = this.loan.expense_image;
      this.expensesAmount = this.loan.expenses_amount;
      this.mileageEnd = this.loan.mileage_end;
      this.mileageEndImage = this.loan.mileage_end_image;
      this.mileageStart = this.loan.mileage_start;
      this.mileageStartImage = this.loan.mileage_start_image;
      this.$emit("estimate");
    },
    open() {
      if (this.isEditable) {
        this.$refs.editableBox.setIsEditing(true);
      }
    },
  },
};
</script>

<template>
  <loan-page-editable-box ref="editableBox" :initially-editing="startsEditable">
    <template #default="{ edit }">
      <div class="w-100">
        <div class="loan-info">
          <div
            class="loan-info-row"
            :class="{
              'loan-info-row-with-images':
                loan.mileage_start_image || loan.mileage_end_image || loan.expense_image,
            }"
          >
            <template v-if="needsDetailedMileage">
              <div v-if="loan.mileage_start" class="loan-info-bit">
                <div class="info-title">
                  Départ

                  <safe-image
                    v-if="loan.mileage_start_image"
                    link
                    :image="loan.mileage_start_image"
                  >
                    <template #link="{ modal }">
                      <icon-button
                        v-b-modal="modal"
                        icon="file-earmark-image"
                        variant="ghost-secondary"
                        size="sm"
                      >
                      </icon-button>
                    </template>
                  </safe-image>
                </div>
                <div class="info-value">{{ loan.mileage_start }} KM</div>
              </div>
              <div v-if="loan.mileage_end" class="loan-info-bit">
                <div class="info-title">
                  Retour

                  <safe-image v-if="loan.mileage_end_image" link :image="loan.mileage_end_image">
                    <template #link="{ modal }">
                      <icon-button
                        v-b-modal="modal"
                        icon="file-earmark-image"
                        variant="ghost-secondary"
                        size="sm"
                      >
                      </icon-button>
                    </template>
                  </safe-image>
                </div>
                <div class="info-value">{{ loan.mileage_end }} KM</div>
              </div>
            </template>
            <div class="loan-info-bit">
              <div class="info-title">Distance {{ mileageFilled ? "" : "estimée" }}</div>
              <div class="info-value">{{ loan.actual_distance }} KM</div>
            </div>
            <template v-if="showDuration">
              <div class="loan-info-bit">
                <div class="info-title">Durée</div>
                <div class="info-value">{{ loan.duration_in_minutes | durationInHours }}</div>
              </div>
            </template>
            <template v-else-if="needsExpenses">
              <div class="loan-info-bit">
                <div class="info-title">
                  Dépenses

                  <safe-image v-if="loan.expense_image" link :image="loan.expense_image">
                    <template #link="{ modal }">
                      <icon-button
                        v-b-modal="modal"
                        icon="file-earmark-image"
                        variant="ghost-secondary"
                        size="sm"
                      >
                      </icon-button>
                    </template>
                  </safe-image>
                </div>
                <div class="info-value">{{ loan.expenses_amount || 0 | currency }}</div>
              </div>
            </template>
          </div>

          <icon-button
            v-if="isEditable"
            size="sm"
            role="edit"
            square
            xs="block"
            sm="block"
            md="block"
            @click="edit"
          >
            Modifier
          </icon-button>
        </div>
        <div v-if="needsUserValidation">
          <hr />
          <icon-button icon="check2-circle" variant="success" :onclick="validate">
            Valider les information
          </icon-button>
        </div>
      </div>
    </template>
    <template #form="{ close }">
      <validation-observer ref="observer" v-slot="{ passes }">
        <b-form>
          <template v-if="needsDetailedMileage">
            <div class="booklet-intro">
              <div>
                <p class="font-weight-bold">Distance parcourue</p>
                <p>
                  Comme preuve pour les kilométrages au départ et à l'arrivée, vous pouvez ajouter
                  des photos du tableau de bord <strong>OU</strong> inscrire les KM dans le carnet
                  de bord qui se trouve dans l'auto.
                </p>
              </div>
              <img class="booklet" src="/carnetdebord.png" alt="carnet de bord" />
            </div>

            <conditional-context label="Au départ" background="white" show class="mx-0">
              <!-- Nested validation observer is required to handle same labels for mileage and image-->
              <validation-observer>
                <div class="info-input-with-file">
                  <forms-validated-input
                    id="mileage_start"
                    v-model="mileageStart"
                    class="info-input"
                    name="mileage_start"
                    label="KM au compteur"
                    type="text"
                    :rules="{ required: true, min_value: 0 }"
                    placeholder=""
                    mask="#########"
                    :formatter="integer"
                    @blur="estimate"
                  />
                  <div>
                    <forms-image-uploader
                      v-model="mileageStartImage"
                      label="Photo du tableau de bord"
                      field="mileage_start_image"
                      tiny
                      @loading="mileageStartImageLoading = $event"
                    />
                  </div>
                </div>
              </validation-observer>
            </conditional-context>

            <conditional-context
              v-if="showReturnForm"
              label="Au retour"
              background="white"
              show
              class="mx-0"
            >
              <div class="info-input-with-file">
                <forms-validated-input
                  id="mileage_end"
                  v-model="mileageEnd"
                  class="info-input"
                  name="mileage_end"
                  label="KM au compteur"
                  type="text"
                  :rules="
                    mileageEndImage
                      ? { required: true, min_value: mileageStart }
                      : { min_value: mileageStart }
                  "
                  placeholder=""
                  mask="#########"
                  :formatter="integer"
                  @blur="estimate"
                />
                <div>
                  <forms-image-uploader
                    ref="mileageEndUploader"
                    v-model="mileageEndImage"
                    label="Photo du tableau de bord"
                    field="mileage_end_image"
                    tiny
                    @loading="mileageEndImageLoading = $event"
                  />
                </div>
              </div>

              <div v-if="needsExpenses" class="info-input-with-file">
                <forms-validated-input
                  id="expenses_amount_input"
                  v-model="expensesAmount"
                  :rules="expenseImage ? { required: true } : null"
                  class="info-input"
                  name="expenses_amount"
                  type="currency"
                  :min="0"
                  label="Montant des dépenses"
                  @blur="estimate"
                />
                <div>
                  <forms-image-uploader
                    ref="expensesEndUploader"
                    v-model="expenseImage"
                    label="Photo de la facture"
                    field="expense_image"
                    tiny
                    description="Cette photo doit afficher le détails des dépenses."
                    @loading="expenseImageLoading = $event"
                  />
                </div>
              </div>
              <b-alert
                v-if="needsExpenses && expensesAmount > maxExpenses"
                variant="warning"
                show
                class="mb-3"
              >
                <strong>Attention&nbsp;:</strong> le montant des dépenses ne peut dépasser la
                compensation au propriétaire (<strong>{{ maxExpenses | currency }}</strong
                >). Si les dépenses ont réellement excédé ce montant, contactez
                {{ isBorrower ? loanableOwnerUser.name : loan.borrower_user.name }}
                directement pour gérer le remboursement.
              </b-alert>
            </conditional-context>
          </template>
          <template v-else>
            <forms-validated-input
              id="estimated_distance"
              v-model="estimatedDistance"
              class="info-input"
              name="estimated_distance"
              label="Distance estimée"
              type="text"
              :rules="{ min_value: 0 }"
              placeholder=""
              mask="#########"
              :formatter="integer"
              @blur="estimate"
            />

            <div v-if="needsExpenses" class="info-input-with-file">
              <forms-validated-input
                id="expenses_amount_input"
                v-model="expensesAmount"
                class="info-input"
                name="expenses_amount"
                type="currency"
                :min="0"
                label="Montant des dépenses"
                @blur="estimate"
              />
              <div>
                <forms-image-uploader
                  ref="expensesEndUploader"
                  v-model="expenseImage"
                  label="Photo de la facture"
                  field="expense_image"
                  tiny
                  description="Cette photo doit afficher le détails des dépenses."
                  @loading="expenseImageLoading = $event"
                />
              </div>
            </div>
          </template>
          <div class="button-list">
            <icon-button
              role="save"
              :disabled="submitDisabled || !changed"
              :onclick="async () => passes(() => save(close))"
              >Enregistrer</icon-button
            >
            <icon-button v-if="startsEditable" :disabled="!changed" role="reset" :onclick="reset">
              Réinitialiser
            </icon-button>
            <icon-button
              v-else
              role="cancel"
              :onclick="
                () => {
                  close();
                  reset();
                }
              "
              >Annuler</icon-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </loan-page-editable-box>
</template>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.loan-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;

  .loan-info-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
    align-items: center;
  }

  .loan-info-row-with-images .info-title {
    height: 2rem;
  }

  .loan-info-bit {
    flex: 1;
    text-align: center;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    + .loan-info-bit {
      border-left: 1px solid $light-grey;
      padding-left: 0.5rem;
    }
    &:has(+ .loan-info-bit) {
      padding-right: 0.5rem;
    }
  }
}
.booklet-intro {
  display: flex;
  gap: 1rem;
  background: #eef8f7;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  .booklet {
    max-width: 5rem;
    object-fit: contain;
  }
}

.info-title {
  margin-bottom: 0.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.info-value {
  font-size: 1.125rem;
  font-weight: bold;
  color: $primary;
}

.info-input-with-file {
  display: flex;
  gap: 1rem;
  > * {
    flex: 1;
  }
  .info-input {
    flex: 0;
    flex-basis: 10rem;
  }
}

@include media-breakpoint-down(md) {
  .loan-info {
    .loan-info-row {
      .loan-info-bit {
        flex: 0;
        flex-basis: 45%;
        flex-grow: 1;
        padding: 0.25rem 0;

        &:nth-child(2) + .loan-info-bit {
          border-left: none;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .info-input-with-file {
    display: block;
    .info-input {
      flex: 1;
      flex-basis: auto;
    }
  }
}
</style>
