import { getAdminCommunities, isAdminOfCommunity } from "@/helpers/permissions/communities";
import { isGlobalAdmin } from "@/helpers/permissions/users";

export function getOwner(loanable) {
  return loanable?.user_roles?.find((r) => r.role === "owner")?.user;
}

export function isOwner(user, loanable) {
  return getOwner(loanable).id === user.id;
}

export function isCoownerOrOwner(user, loanable) {
  return !!loanable?.user_roles?.find(
    (r) => r.user_id === user.id && ["owner", "coowner"].includes(r.role)
  );
}

export function canCreateLoanable(user) {
  if (getAdminCommunities(user).length > 0) {
    return true;
  }

  return isGlobalAdmin(user);
}

export function isAdminOfLoanable(user, loanable) {
  return (
    isGlobalAdmin(user) ||
    (loanable.community_ids || []).find((id) => isAdminOfCommunity(user, { id })) !== undefined
  );
}

export function canEditLoanable(user, loanable) {
  return (
    isGlobalAdmin(user) || isAdminOfLoanable(user, loanable) || isCoownerOrOwner(user, loanable)
  );
}

export function canDeleteLoanable(user, loanable) {
  return isOwner(user, loanable) || isGlobalAdmin(user);
}

export function canEditRole(user, loanable, role) {
  if (isOwner(user, loanable) || isGlobalAdmin(user)) {
    return true;
  }

  if (role.role === "coowner") {
    return role.user_id === user.id;
  }

  return false;
}

export function canEditRoleUser(user, loanable, role) {
  // Global admin can change owner user. Can also set user when creating new role.
  return (isGlobalAdmin(user) && role.role === "owner") || !role.id;
}

export function canEditRolePaidAmounts(user, loanable, role) {
  if (isGlobalAdmin(user)) {
    return true;
  }

  // Owners can change parameters for other roles
  return isOwner(user, loanable) && role.role !== "owner";
}
