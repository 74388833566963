import RestModule from "../RestModule";
import { del, get, put, post } from "@/requests/server";

export default new RestModule(
  "loanables",
  {},
  {
    /**
     * Attempts to retrieve a loanable which may be accessible by the user
     */
    async tryRetrieveOne({ dispatch, commit, state }, { params, id }) {
      commit("loaded", false);
      commit("loading", true);

      await dispatch("options");

      try {
        const { data } = await get(`/${state.slug}/${id}`, {
          axiosRequestConfig: { params },
          notifications: { action: "chargement" },
          cleanupCallback: () => commit("loading", false),
          requestOptions: { expects: [404] },
        });
        commit("item", data);
        commit("initialItem", data);

        commit("loaded", true);
        commit("loading", false);
      } catch (e) {
        if (e.request && e.request.status === 404) {
          commit(
            "addNotification",
            {
              content: "Ce véhicule n'est pas partagé dans votre communauté.",
              title: "Véhicule inaccessible",
              variant: "danger",
            },
            { root: true }
          );
        }
        throw e;
      }
    },
    async disable({ commit, state }, id) {
      const { data: deleted } = await del(`/${state.slug}/${id}`, {
        notifications: { action: "Archivage du véhicule", onSuccess: "Archivage réussi!" },
      });

      commit("deleted", deleted);
    },
    async addRole({ commit, state }, { loanable, role }) {
      const { data } = await post(`/loanables/${loanable.id}/roles`, role, {
        notifications: {
          action: "ajout de role",
          onSuccess: "Rôle ajouté!",
        },
      });

      const userRoles = [
        ...(state.item.user_roles ?? []),
        {
          ...data,
          loanable,
        },
      ];

      commit("patchItem", { user_roles: userRoles });
      commit("patchInitialItem", { user_roles: userRoles });
    },
    async editRole({ commit, state }, { loanable, role }) {
      const { data } = await put(`/loanables/roles/${role.id}`, role, {
        notifications: {
          action: "changement",
          onSuccess: "changements sauvegardés!",
        },
      });

      const userRoles = [
        ...state.item.user_roles.map(function (r) {
          if (r.id === role.id) {
            return {
              ...data,
              loanable,
            };
          } else {
            return r;
          }
        }),
      ];

      commit("patchItem", { user_roles: userRoles });
      commit("patchInitialItem", { user_roles: userRoles });
    },
    async removeRole({ commit, state }, { roleId }) {
      await del(`/loanables/roles/${roleId}`, {
        notifications: {
          action: "retrait d'unl rôle",
          onSuccess: "Role retiré-e!",
        },
      });

      const userRoles = [...state.item.user_roles.filter((r) => r.id !== roleId)];

      commit("patchItem", { user_roles: userRoles });
      commit("patchInitialItem", { user_roles: userRoles });
    },
  }
);
