<script>
import FormsDateTimePicker from "@/components/Forms/DateTimePicker.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import FileDownloadLink from "@/components/shared/FileDownloadLink.vue";
import YearlyIncomeReportsBox from "@/views/admin/YearlyIncomeReportsBox.vue";
import dayjs from "dayjs";

export default {
  name: "AdminReports",
  components: {
    FileDownloadLink,
    YearlyIncomeReportsBox,
    FormsValidatedInput,
    FormsDateTimePicker,
  },
  data() {
    return {
      balanceUpToDate: null,
      trimester: null,
      balanceNonZero: false,
    };
  },
  computed: {
    trimesterOptions() {
      let options = [];
      let currentYear = dayjs().year();
      let currentQuarter = Math.ceil((dayjs().month() + 1) / 3);
      let monthsSince2020Q1 = 0;
      for (let year = 2020; year <= currentYear; year++) {
        for (let quarter = 1; quarter <= 4; quarter++) {
          if (year === currentYear && quarter > currentQuarter) {
            break;
          }
          let text = `${year}Q${quarter}`;
          if (year === currentYear && quarter === currentQuarter) {
            text += " (en cours)";
          }
          options.push({
            value: monthsSince2020Q1++,
            text,
          });
        }
      }
      return options.reverse();
    },
    trimesterStart() {
      if (this.trimester === null) {
        return null;
      }
      return dayjs("2020-01-01T00:00:00")
        .add(this.trimester * 3, "months")
        .toISOString();
    },
    trimesterEnd() {
      if (this.trimester === null) {
        return null;
      }
      return dayjs("2020-04-01T00:00:00")
        .add(this.trimester * 3, "months")
        .toISOString();
    },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("D MMMM YYYY HH:mm z (Z)");
    },
    formatInsuranceReportName() {
      return `rapport_assurances_${
        this.trimesterOptions[this.trimesterOptions.length - this.trimester - 1].text
      }.csv`;
    },
    formatInsuranceReportUrl() {
      let params = new URLSearchParams();
      if (this.trimesterStart) {
        params.set("from", this.trimesterStart);
      }
      if (this.trimesterEnd) {
        params.set("to", this.trimesterEnd);
      }
      return "/reports/insurance/generate?" + params.toString();
    },
    formatBalanceReportName() {
      return this.balanceUpToDate
        ? `rapport_soldes_${this.balanceUpToDate}.csv`
        : `rapport_soldes_${dayjs().startOf("minute").toISOString()}.csv`;
    },
    formatBalanceReportUrl() {
      let params = new URLSearchParams();
      if (this.balanceUpToDate) {
        params.set("at", this.balanceUpToDate);
      }
      if (this.balanceNonZero) {
        params.set("non_zero_only", 1);
      }
      return "/reports/balance/generate?" + params.toString();
    },
  },
};
</script>

<template>
  <div>
    <h2>Rapports</h2>
    <b-card>
      <h3>Déclaration trimestrielle pour les assurances</h3>
      <b-form>
        <b-form-group
          label="Trimestre"
          :description="
            trimester ? `${formatDate(trimesterStart)} au ${formatDate(trimesterEnd)}` : null
          "
        >
          <b-select v-model="trimester" :options="trimesterOptions" />
        </b-form-group>

        <file-download-link
          v-if="trimester !== null"
          :api-url="formatInsuranceReportUrl()"
          download
          :filename="formatInsuranceReportName()"
          button-label="Générer le rapport"
        />
      </b-form>
    </b-card>

    <b-card>
      <h3>Rapport des soldes des utilisateurs</h3>
      <b-form>
        <b-form-group
          label="Soldes historiques en date du"
          description="Laisser vide pour soldes actuels"
        >
          <forms-date-time-picker v-model="balanceUpToDate" clearable show-timezone />
        </b-form-group>
        <forms-validated-input
          v-model="balanceNonZero"
          type="checkbox"
          name="non-zero"
          label="Soldes non nuls uniquement"
        />
        <file-download-link
          :api-url="formatBalanceReportUrl()"
          download
          :filename="formatBalanceReportName()"
          button-label="Générer le rapport"
        />
      </b-form>
    </b-card>
    <yearly-income-reports-box />
  </div>
</template>

<style scoped lang="scss">
.card {
  box-shadow: $small-shadow;
  + .card {
    margin-top: 1rem;
  }
}
</style>
