<template>
  <file-download-link
    :api-url="'/files/' + file.id"
    :filename="file.original_filename"
    :download="download"
  >
    <slot></slot>
  </file-download-link>
</template>
<script>
import FileDownloadLink from "@/components/shared/FileDownloadLink.vue";
import Vue from "vue";

export default Vue.extend({
  name: "SafeFile",
  components: { FileDownloadLink },
  props: {
    file: {
      type: Object,
      required: true,
    },
    download: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>
