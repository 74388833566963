import { userIsApproved, userIsRegistered } from "@/helpers/permissions/users";
import { getOwner } from "@/helpers/permissions/loanables";

export default {
  computed: {
    canLoanVehicle() {
      return (
        this.isLoggedIn &&
        this.user.borrower &&
        this.user.communities &&
        this.user.communities.reduce((acc, c) => acc || (!!c.approved_at && !c.suspended_at), false)
      );
    },
    hasCommunity() {
      return this.isLoggedIn && this.user.communities && this.user.communities.length > 0;
    },
    mainCommunity() {
      if (this.user.communities && this.user.communities.length > 0) {
        return this.user.communities[0];
      } else {
        return false;
      }
    },
    canFillDriverProfile() {
      for (const community of this.user.communities) {
        if (community.shared_loanable_types?.includes("car")) {
          return true;
        }
      }
      return false;
    },
    userNeedsIdentityProof() {
      for (const community of this.user.communities) {
        if (community.requires_identity_proof) {
          return true;
        }
      }
      return false;
    },
    hasSubmittedNecessaryProofs() {
      return (
        this.user.residency_proof?.length > 0 &&
        (!this.userNeedsIdentityProof || this.user.identity_proof?.length > 0)
      );
    },
    waitingForProfileApproval() {
      return (
        this.isLoggedIn &&
        this.hasCompletedRegistration &&
        this.hasSubmittedNecessaryProofs &&
        !this.hasProfileApproved
      );
    },
    hasProfileApproved() {
      return userIsApproved(this.user);
    },
    hasCompletedRegistration() {
      return this.isLoggedIn && this.isRegistered;
    },
    isGlobalAdmin() {
      return this.isLoggedIn && this.user.role === "admin";
    },
    isAdmin() {
      return (
        this.isLoggedIn &&
        (this.user.role === "admin" ||
          (this.user.communities && !!this.user.communities.find((c) => c.role === "admin")))
      );
    },
    isLoggedIn() {
      return !!this.user;
    },
    // Has finalized his account creation
    isRegistered() {
      return userIsRegistered(this.user);
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdminOfCommunity(community) {
      return (
        this.isGlobalAdmin ||
        (community &&
          !!this.user.communities.find((c) => c.id === community.id && c.role === "admin"))
      );
    },
    // has admin priveleges over other user.
    isAdminOfUser(otherUser) {
      return (
        this.isGlobalAdmin || (otherUser && otherUser?.communities?.find(this.isAdminOfCommunity))
      );
    },
    isAdminOfLoanable(loanable) {
      return this.isAdminOfCommunity(loanable?.community) || this.isAdminOfUser(getOwner(loanable));
    },
  },
};
