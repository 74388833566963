<template>
  <b-card class="bordered-card">
    <div class="mb-3 font-weight-bold">
      {{ role.user.full_name ? role.user.full_name : "Nouveau-elle utilisateurice" }}
    </div>
    <forms-validated-input
      v-if="canEditUser"
      label="Utilisateurice"
      :name="'user_id_' + (role ? role.id : 0)"
      type="relation"
      :disabled="disabled || loading"
      :query="userQuery"
      :value="editedRole.user_id"
      :object-value="editedRole.user"
      :rules="{ required: true }"
      mode="eager"
      @relation="setUser"
    />

    <forms-validated-input
      v-model="editedRole.show_as_contact"
      label="Afficher comme contact"
      :name="'show_as_contact_' + (role ? role.id : 0)"
      type="checkbox"
      :disabled="disabled || loading"
      description="Si ses coordonnées devraient être affichées aux emprunteurs-ses."
    />

    <forms-validated-input
      v-if="editedRole.role === 'coowner'"
      v-model="editedRole.title"
      label="Titre"
      :name="'title_' + (role ? role.id : 0)"
      description="Ce titre clarifie la responsabilité de cette personne pour le véhicule. Ex. marraine/parrain, gardien-ne, coordinateur-trice, co-propriétaire, etc."
      type="text"
      :disabled="disabled || loading"
    />
    <template v-if="canEditPaidAmounts">
      <forms-validated-input
        v-if="editedRole.role === 'coowner'"
        v-model="editedRole.pays_loan_price"
        label="Paie le tarif de l'emprunt"
        :name="'pays_loan_price_' + (role ? role.id : 0)"
        description="Si applicable, cette personne paie le coût lié à la durée et à la distance parcourue lors de ses emprunts de ce véhicule."
        type="checkbox"
        :disabled="disabled || loading"
      ></forms-validated-input>

      <template v-if="loanableHasPaidInsurance">
        <forms-validated-input
          v-if="editedRole.role === 'coowner'"
          v-model="editedRole.pays_loan_insurance"
          label="Paie l'assurance liée à l'emprunt"
          :name="'pays_loan_insurance_' + (role ? role.id : 0)"
          description="Si applicable, cette personne paie le coût lié à l'assurance lors de ses emprunts de ce véhicule."
          type="checkbox"
          :disabled="disabled || loading"
        ></forms-validated-input>

        <b-alert
          v-if="editedRole.role !== `owner` && !editedRole.pays_loan_insurance"
          variant="warning"
          show
        >
          <strong>Attention&nbsp;:</strong> cette personne ne sera pas assurée par LocoMotion lors
          de ses trajets.
        </b-alert>
      </template>
    </template>

    <div class="action-buttons">
      <icon-button role="save" :loading="loading" :disabled="disabled" :onclick="save">
        Enregistrer
      </icon-button>
      <icon-button role="cancel" :disabled="disabled || loading" @click.prevent="cancel">
        Annuler
      </icon-button>
      <layout-loading v-if="loading" with-button />
    </div>
  </b-card>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { canEditRolePaidAmounts, canEditRoleUser } from "@/helpers/permissions/loanables";

export default {
  name: "LoanableUserRoleForm",
  components: { IconButton, FormsValidatedInput },
  props: {
    role: { type: Object, required: true },
    loanable: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    onsave: { type: Function, required: true },
  },
  data() {
    return {
      editedRole: {
        ...this.role,
      },
      userQuery: {
        slug: "users",
        value: "user_id",
        text: "full_name",
        details: "email",
        params: {
          fields: "full_name",
        },
      },
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    loanableHasPaidInsurance() {
      return this.loanable.type === "car";
    },
    canEditUser() {
      return canEditRoleUser(this.user, this.loanable, this.role);
    },
    canEditPaidAmounts() {
      return canEditRolePaidAmounts(this.user, this.loanable, this.role);
    },
  },
  methods: {
    async save() {
      if (!this.loanableHasPaidInsurance) {
        this.role.pays_loan_insurance = false;
      }

      await this.onsave(this.editedRole);
      this.$emit("done", this.role);
    },
    cancel() {
      this.$emit("done", this.role);
    },
    setUser(user) {
      this.editedRole.user_id = user.id;
      this.editedRole.user = user;
    },
  },
};
</script>

<style scoped>
.action-buttons {
  display: flex;
  gap: 0.5rem;
}
</style>
