import { render, staticRenderFns } from "./LoanableListActions.vue?vue&type=template&id=35bd0e82&scoped=true"
import script from "./LoanableListActions.vue?vue&type=script&lang=js"
export * from "./LoanableListActions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35bd0e82",
  null
  
)

export default component.exports