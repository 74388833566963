<template>
  <paginated-table
    id="admin-users-table"
    ref="table"
    endpoint="users"
    :columns="columns"
    :extra-filters="extraFilters"
    :extra-data="extraData"
    :fetch-params="{ for: 'admin' }"
    selectable
    :label="$tc('model_name', 2)"
    sync-filters-with-url
    row-link
    :show-action-column="canArchiveUser || canImpersonateUser"
    @row-selected="
      (users) => {
        selectedUsers = users;
        allUsersSelected = false;
      }
    "
    @filters-changed="filters = $event"
  >
    <template #head-buttons>
      <icon-button v-if="canCreateUser" role="add" to="/admin/users/new">
        {{ $t("list.create") | capitalize }}
      </icon-button>

      <b-button-group v-if="canResetUserPassword || canSendRegistrationEmails" variant="info">
        <b-dropdown left :disabled="selectedUsers.length === 0" text="Actions groupées">
          <b-dropdown-item v-if="canResetUserPassword" @click="sendPasswordResetEmail">
            Courriel de réinit. de mot de passe
          </b-dropdown-item>
          <b-dropdown-item v-if="canSendRegistrationEmails" @click="sendRegistrationSubmittedEmail">
            Courriel de bienvenue (inscr. complétée)
          </b-dropdown-item>
          <b-dropdown-item v-if="canSendRegistrationEmails" @click="sendRegistrationApprovedEmail">
            Courriel de bienvenue (approbation)
          </b-dropdown-item>
          <b-dropdown-item v-if="canSendRegistrationEmails" @click="sendRegistrationRejectedEmail">
            Courriel de refus d'inscription
          </b-dropdown-item>
          <b-dropdown-divider />
          <template v-if="communityRegionFilter">
            <b-dropdown-item @click="addAllToCommunity">
              Ajouter à la communtauté sélectionnée (id: {{ communityRegionFilter }})
            </b-dropdown-item>
            <b-dropdown-divider />
          </template>
          <b-dropdown-item disabled
            >{{ $tc("list.selected", selectedUserCount, { count: selectedUserCount }) }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!allUsersSelected && $refs.table && $refs.table.total !== selectedUsers.length"
            @click="
              () => {
                $refs.table.selectAll();
                // Need to do this on the next tick, since the table.selectAll would undo it
                $nextTick(() => (allUsersSelected = true));
              }
            "
          >
            Sélectionner tous les membres correspondant aux filtres ({{
              $refs.table && $refs.table.total
            }})
          </b-dropdown-item>
        </b-dropdown>
      </b-button-group>
    </template>

    <template #cell(actions)="{ item }">
      <div class="user-actions">
        <admin-list-actions
          v-if="!item.data_deleted_at"
          :item="item"
          :item-name="item.full_name"
          slug="users"
          :show-destroy="canArchiveUser"
          :show-restore="canArchiveUser"
          :restore-params="restoreParams"
          :archive-label="$t('archive')"
          :restore-label="$t('restore')"
          archive-icon="person-x"
          @change="$refs.table.refresh"
        >
          <template #destroy-question>
            <div>
              <strong
                >Êtes-vous sûr-e de vouloir désactiver le compte de {{ item.full_name }}?</strong
              >
              <p class="mt-2 mb-0">
                Assurez-vous que {{ item.full_name }} n'a pas d'emprunts en cours en tant
                <router-link
                  target="_blank"
                  :to="`/admin/loans?borrower_user.id=${item.id}&status=in_process`"
                  >qu'emprunteur</router-link
                >
                ou en tant que
                <router-link
                  target="_blank"
                  :to="`/admin/loans?loanable.owner_user_id=${item.id}&status=in_process`"
                >
                  propriétaire</router-link
                >.
              </p>
            </div>
          </template>
          <template #restore-question>
            <div>
              <strong>Êtes-vous sûr-e de vouloir réactiver {{ item.full_name }}?</strong>
              <b-form-checkbox v-model="restoreParams.restore_loanables" class="mt-3">
                Restaurer les véhicules?
              </b-form-checkbox>
              <b-form-checkbox
                v-model="restoreParams.restore_availability"
                :disabled="!restoreParams.restore_loanables"
                class="mt-3"
              >
                Restaurer les disponibilités des véhicules?
              </b-form-checkbox>
            </div>
          </template>
          <b-dropdown-item
            v-if="!item.deleted_at && canImpersonateUser"
            :id="'mandate-' + item.id"
            @click="mandate(item.id)"
          >
            <b-icon icon="person-badge" variant="warning" /> Connexion
          </b-dropdown-item>
          <validated-button
            v-if="item.deleted_at"
            variant="danger"
            type="b-dropdown-item"
            icon="x-octagon-fill"
            :action="
              async function () {
                await fullyDelete(item.id);
              }
            "
            label="Supprimer les données"
            :question="`Êtes-vous sûr-e de vouloir supprimer les données de ${item.full_name}? Cette action est finale. Les données ne pourront pas être récupérées ultérieurement.`"
          />
        </admin-list-actions>
      </div>
    </template>
  </paginated-table>
</template>

<script>
import AdminListActions from "@/components/Admin/ListActions.vue";
import IconButton from "@/components/shared/IconButton.vue";
import locales from "@/locales";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { canSeeAdmins, canSeeDeletedUser, isGlobalAdmin } from "@/helpers/permissions/users";
import { Column, Filter } from "@/components/shared/PaginatedTableColumns";
import { del, put } from "@/requests/server";
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";

export default {
  name: "AdminUsers",
  components: {
    IconButton,
    ValidatedButton,
    PaginatedTable,
    AdminListActions,
  },
  data() {
    const extraFilters = [
      new Filter("communities.id", "Communauté", "relation", {
        relation: "communities",
        label: "name",
        field: "id",
        params: { for: "admin" },
      }),
      new Filter("is_in_community_region", "Sans communauté, mais dans la région de", "relation", {
        relation: "communities",
        label: "name",
        field: "id",
        params: { for: "admin", type: "borough" },
      }),
    ];

    const columns = [
      new Column("id", "ID", "id"),
      new Column("address", "Adresse", "text", { showByDefault: false }),
      new Column("created_at", "Créé le", "date", { showByDefault: false }),
      new Column("full_name", "Nom", "text", {
        urlFct: (item) => !item.deleted_at && `/admin/users/${item.id}`,
      }),
      new Column("email", "Courriel"),
      new Column("phone", "Téléphone"),
      Column.withSelect(
        "is_fleet",
        "Flotte",
        [
          { value: null, label: "Tous" },
          { value: true, label: "Oui", variant: "success" },
          { value: false, label: "Non", variant: "primary" },
        ],
        {
          showByDefault: false,
        }
      ),
    ];

    const extraData = [];
    if (canSeeDeletedUser(this.$store.state.user)) {
      extraFilters.push(new Filter("is_deleted", "Compte désactivé?", "boolean"));
      extraData.push("deleted_at");
      extraData.push("data_deleted_at");
    }
    if (canSeeAdmins(this.$store.state.user)) {
      extraFilters.push(new Filter("global_admins", "Admin global?", "boolean"));
      extraFilters.push(new Filter("community_admins", "Admin de communauté?", "boolean"));
    }

    return {
      allUsersSelected: false,
      selectedUsers: [],
      filters: {},
      columns,
      extraFilters,
      extraData,
      restoreParams: {
        restore_loanables: false,
        restore_availability: false,
      },
    };
  },
  computed: {
    canCreateUser() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
    canArchiveUser() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
    canEditUser() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
    canImpersonateUser() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
    canResetUserPassword() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
    canSendRegistrationEmails() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
    communityRegionFilter() {
      return this.filters.is_in_community_region;
    },
    selectedUserCount() {
      return this.allUsersSelected ? this.$refs.table.total : this.selectedUsers.length;
    },
  },
  methods: {
    async mandate(mandatedUserId) {
      this.$store.dispatch("account/mandate", { mandatedUserId });
    },
    async fullyDelete(userId) {
      await del(`/users/${userId}`, {
        axiosRequestConfig: { data: { delete_data: true } },
        notifications: { action: "Supression des données", onSuccess: "Données supprimées!" },
      });
      this.$refs.table.refresh();
    },
    getMassActionParams() {
      if (this.allUsersSelected) {
        return {
          ...this.$refs.table.fetchParams,
          ...this.$refs.table.filters,
          per_page: "*",
        };
      }
      return {
        id: this.selectedUsers.map((s) => s.id).join(","),
      };
    },

    async addAllToCommunity() {
      await put(
        `/users/add_to_community/${this.communityRegionFilter}`,
        this.getMassActionParams(),
        { notifications: { action: "ajout des membres à la communauté" } }
      );

      this.$store.commit("addNotification", {
        content: `Communauté ajoutée à ${this.selectedUserCount} membre(s).`,
        title: "Succès",
        variant: "success",
      });

      this.$refs.table.refresh();
    },
    displayMailStatus(data) {
      const { report } = data;

      if (report) {
        const succeeded = report.reduce(
          (acc, s) => acc + (s.response.status === "success" ? 1 : 0),
          0
        );
        const failed = report.reduce((acc, s) => acc + (s.response.status === "error" ? 1 : 0), 0);
        this.$store.commit("addNotification", {
          content: `Résultat de l'envoi: ${succeeded} réussi(s) et ${failed} échoués`,
          title: "Courriels envoyés",
          variant: "success",
          type: "send_email",
        });
      }
    },
    async sendPasswordResetEmail() {
      const { data } = await put(`/users/send/password_reset`, null, {
        axiosRequestConfig: {
          params: this.getMassActionParams(),
        },
        notifications: { action: "envoi de courriel" },
      });
      this.displayMailStatus(data);
    },
    async sendRegistrationSubmittedEmail() {
      const { data } = await put(`/users/send/registration_submitted`, null, {
        axiosRequestConfig: {
          params: this.getMassActionParams(),
        },
        notifications: { action: "envoi de courriel" },
      });
      this.displayMailStatus(data);
    },
    async sendRegistrationApprovedEmail() {
      const { data } = await put(`/users/send/registration_approved`, null, {
        axiosRequestConfig: {
          params: this.getMassActionParams(),
        },
        notifications: { action: "envoi de courriel" },
      });
      this.displayMailStatus(data);
    },
    async sendRegistrationRejectedEmail() {
      const { data } = await put(`/users/send/registration_rejected`, null, {
        axiosRequestConfig: {
          params: this.getMassActionParams(),
        },
        notifications: { action: "envoi de courriel" },
      });
      this.displayMailStatus(data);
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.forms,
        ...locales.en.users,
      },
      fr: {
        ...locales.fr.forms,
        ...locales.fr.users,
      },
    },
  },
};
</script>

<style lang="scss">
.user-actions {
  display: flex;
}
</style>
