<template>
  <div>
    <b-alert v-if="!hasOwner" variant="info" show>
      Ajoutez un propriétaire avant de configurer les droits de gestion.
    </b-alert>
    <b-alert v-if="hasNoVisibleContacts" variant="warning" show>
      Personne n'est présentement affiché comme contact lors des emprunts.
    </b-alert>
    <b-row>
      <b-col v-for="role in orderedRoles" :key="role.id" xl="6" class="mb-3">
        <user-blurb
          v-if="!editingIds.includes(role.id)"
          class="loanable-user-role"
          :user="role.user"
          show-phone
          :description="roleDescription(role)"
          :show-admin-link="showAdminLinks"
        >
          <template #nameicon>
            <b-icon
              v-if="role.show_as_contact"
              v-b-tooltip.hover
              title="Visible aux emprunteurs"
              variant="primary"
              icon="eye-fill"
            />
          </template>

          <template #buttons>
            <b-col
              class="action-buttons mt-3 mt-sm-0 d-flex justify-content-center flex-column"
              cols="12"
              sm="auto"
            >
              <icon-button
                v-if="role.id === 0"
                :disabled="disabled"
                size="sm"
                role="add"
                variant="ghost-secondary"
                @click="() => beginEditRole(role)"
              >
                Ajouter
              </icon-button>
              <template v-else-if="canEditRole(role)">
                <icon-button
                  :disabled="disabled"
                  size="sm"
                  role="edit"
                  icon="pencil"
                  @click="() => beginEditRole(role)"
                >
                  Modifier
                </icon-button>
                <icon-button
                  v-if="role.role !== 'owner'"
                  :disabled="disabled"
                  size="sm"
                  role="remove-item"
                  @click="() => removeRole(role)"
                >
                  Retirer
                </icon-button>
              </template>
            </b-col>
          </template>
        </user-blurb>

        <loanable-user-role-form
          v-else-if="editingIds.includes(role.id)"
          :disabled="disabled"
          :role="role"
          :loanable="loanable"
          :onsave="saveRole"
          @done="finishEditRole"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoanableUserRoleForm from "@/components/Loanable/UserRoleForm.vue";
import IconButton from "@/components/shared/IconButton.vue";
import UserBlurb from "@/components/User/UserBlurb.vue";
import {
  canEditRole,
  canEditRoleUser,
  canEditRolePaidAmounts,
} from "@/helpers/permissions/loanables";

export default {
  name: "LoanableUserRolesForm",
  components: { IconButton, UserBlurb, LoanableUserRoleForm },
  props: {
    disabled: { type: Boolean, default: false },
    loanable: { type: Object, required: true },
    showAdminLinks: { type: Boolean, default: false },
  },
  data() {
    return {
      editingIds: [],
      deletingIds: [],
    };
  },
  computed: {
    orderedRoles() {
      let roles = [...this.loanable.user_roles].sort(
        (a, b) => (a.role !== "owner") - (b.role !== "owner")
      );

      // Add an empty role to generate a form to add a new role.
      roles.push({
        id: 0,
        loanable_id: this.loanable.id,
        user_id: undefined,
        user: {},
        role: "coowner",
        show_as_contact: false,
        pays_loan_price: true,
        pays_loan_insurance: true,
      });

      return roles;
    },
    user() {
      return this.$store.state.user;
    },
    hasOwner() {
      return this.loanable.user_roles.find((r) => r.role === "owner");
    },
    hasNoVisibleContacts() {
      return !this.loanable.user_roles.find((r) => r.show_as_contact);
    },
  },
  methods: {
    canEditRole(role) {
      return canEditRole(this.user, this.loanable, role);
    },
    canEditRoleUser(role) {
      return canEditRoleUser(this.user, this.loanable, role);
    },
    canEditRolePaidAmounts(role) {
      return canEditRolePaidAmounts(this.user, this.loanable, role);
    },
    beginEditRole(role) {
      this.editingIds.push(role.id);
    },
    finishEditRole(role) {
      this.editingIds = this.editingIds.filter((id) => id !== role.id);
    },
    async saveRole(role) {
      if (!role.id) {
        await this.$store.dispatch("loanables/addRole", {
          loanable: { id: this.loanable.id },
          role,
        });
      } else {
        await this.$store.dispatch("loanables/editRole", {
          loanable: { id: this.loanable.id },
          role,
        });
      }
    },
    async removeRole(role) {
      try {
        const isRemovingSelf = role.user_id === this.user.id;

        if (
          !isRemovingSelf ||
          confirm("Êtes-vous certains de vouloir vous enlever en tant que copropriétaire?")
        ) {
          this.deletingIds.push(role.id);
          await this.$store.dispatch("loanables/removeRole", {
            roleId: role.id,
          });

          if (isRemovingSelf) {
            await this.$router.push("/app");
          }
        }
      } finally {
        this.deletingIds = this.deletingIds.filter((i) => i !== role.id);
      }
    },
    roleDescription(role) {
      return role.role === "owner" ? "Propriétaire" : role.title;
    },
  },
};
</script>

<style scoped>
.loading {
  opacity: 0.5;
}
.action-buttons {
  gap: 0.5rem;
}
</style>
