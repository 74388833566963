<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import FileDownloadLink from "@/components/shared/FileDownloadLink.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { slugify } from "@/helpers/filters";
import { get, post, put } from "@/requests/server";
import dayjs from "dayjs";

export default {
  name: "YearlyIncomeReportsBox",
  components: {
    FileDownloadLink,
    PaginatedTable,
    FormsValidatedInput,
    IconButton,
  },
  data() {
    return {
      loading: false,
      year: null,
      user: null,
      yearlyReport: null,
      pollTimeout: null,
      userReportsColumns: [
        new Column("id", "ID", "id", { showByDefault: false }),
        Column.withRelation(
          "user.full_name",
          "Membre",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            for: "admin",
          },
          "user_id",
          {
            urlFct: (item) => (item.user.deleted_at ? null : `/admin/users/${item.user.id}`),
          }
        ),
        Column.withoutFilter("path", "Fichier", "text", { sortable: false }),
      ],
    };
  },
  computed: {
    yearOptions() {
      let firstYear = 2020;
      let currentYear = dayjs().year();
      let options = [];
      // We do not add current year, since the report would not be complete
      for (let year = firstYear; year < currentYear; year++) {
        options.push({ value: year, text: year });
      }
      return options;
    },
    reportStatusText() {
      if (this.yearlyReport.status === "failed") {
        return "Échec";
      }

      if (this.yearlyReport.status === "generating") {
        return "En cours";
      }

      if (this.yearlyReport.status === "generated") {
        return "Généré";
      }

      return "Publié";
    },
    reportStatusVariant() {
      if (!this.yearlyReport) {
        return null;
      }
      if (this.yearlyReport.status === "failed") {
        return "danger";
      }

      if (this.yearlyReport.status === "generating") {
        return "warning";
      }

      if (this.yearlyReport.status === "generated") {
        return "primary";
      }

      return "success";
    },
  },
  beforeDestroy() {
    this.stopPoll();
  },
  methods: {
    stopPoll() {
      clearTimeout(this.pollTimeout);
      this.pollTimeout = null;
    },
    poll(pollCount = 0) {
      if (pollCount > 30) {
        this.stopPoll();
        return;
      }

      if (this.pollTimeout) {
        clearTimeout(this.pollTimeout);
      }
      this.pollTimeout = setTimeout(async () => {
        await this.getYearlyReport();
        if (this.yearlyReport.status === "generating") {
          this.poll(pollCount + 1);
        } else {
          this.stopPoll();
        }
      }, 1000); // once per second
    },
    async generateAllYearlyReports() {
      const { data } = await post(
        `/reports/yearly/${this.year}/income/generate`,
        {},
        { notifications: { action: "génération du rapport" } }
      );
      this.yearlyReport = data;
      this.poll();
    },
    async selectYear() {
      this.stopPoll();
      await this.getYearlyReport();
      if (this.yearlyReport?.status === "generating") {
        this.poll();
      }
    },
    async getYearlyReport() {
      this.loading = true;
      try {
        const { data } = await get(`/reports/yearly/${this.year}/income`, {
          requestOptions: {
            expects: [404],
          },
          cleanupCallback: () => (this.loading = false),
          notifications: { action: "recherche du rapport" },
        });

        this.yearlyReport = data;
      } catch (e) {
        if (e.response.status === 404) {
          this.yearlyReport = null;
          return;
        }
        throw e;
      }
    },
    async publish() {
      if (!this.yearlyReport || this.yearlyReport.status !== "generated" || this.loading) {
        return;
      }

      const confirm = await this.$bvModal.msgBoxConfirm(
        "Chaque membre concerné reçevra une courriel les avertissant de la disponibilité du nouveau document. \n\nNotez que ça ne tiendra pas compte des filtres plus bas, mais que ça va générer la liste entière dans tous les cas.",
        {
          title: "Êtes-vous sûr-e de vouloir publier ces rapports? ",
          okTitle: "Publier",
          cancelTitle: "Annuler",
          okVariant: "success",
          cancelVariant: "ghost-secondary",
          bodyClass: "whitespace-pre",
        }
      );

      if (!confirm) {
        return;
      }

      const { data } = await put(
        `/reports/${this.yearlyReport.id}/publish`,
        {},
        {
          notifications: { action: "publication des rapports" },
        }
      );
      this.yearlyReport = data;
    },
    formatReportFilename(year, userFullName) {
      return `Revenus_${year}_${slugify(userFullName)}.pdf`;
    },
  },
};
</script>

<template>
  <b-card>
    <h3>Revenus annuels des propriétaires</h3>

    <b-form-group label="Année">
      <b-select v-model="year" :options="yearOptions" @change="selectYear"> </b-select>
    </b-form-group>

    <b-tabs v-if="year" content-class="pt-3">
      <b-tab title="Générer pour tous" :class="{ loading: loading && !pollTimeout }">
        <div v-if="yearlyReport">
          <div class="properties">
            <div>
              <div class="label">Date de création</div>
              <div>{{ yearlyReport.created_at | datetime }}</div>
            </div>
            <div>
              <div class="label">Statut</div>
              <div>
                <b-badge :variant="reportStatusVariant">{{ reportStatusText }}</b-badge>
              </div>
            </div>
            <div v-if="yearlyReport.status === 'generating'">
              <div class="label">Progrès</div>
              <div class="progress-wrapper">
                <b-progress :value="yearlyReport.progress" animated height="8px" class="progress" />
                <icon-button
                  role="load"
                  :onclick="getYearlyReport"
                  size="inline"
                  :loading="loading || !!pollTimeout"
                />
              </div>
            </div>
          </div>

          <div v-if="yearlyReport.status === 'generated'" class="button-list mt-3">
            <icon-button
              variant="outline-warning"
              icon="arrow-counterclockwise"
              type="button"
              :onclick="generateAllYearlyReports"
              :loading="!!pollTimeout"
              >Regénérer
            </icon-button>

            <icon-button role="send" type="button" :onclick="publish">Publier</icon-button>
          </div>
        </div>
        <div v-else-if="year && !loading">
          <icon-button role="send" type="button" :onclick="generateAllYearlyReports"
            >Générer pour tous
          </icon-button>
        </div>
        <hr v-if="yearlyReport && yearlyReport.status !== 'generating'" />

        <div v-if="yearlyReport && yearlyReport.status !== 'generating'" class="table-wrapper">
          <paginated-table
            ref="table"
            :fetch-params="{
              publishable_report_id: yearlyReport.id,
            }"
            endpoint="user_reports"
            :columns="userReportsColumns"
            class="mt-3"
            :show-action-column="false"
            :show-generate-csv="false"
          >
            <template #head>
              <h3>Rapports</h3>
            </template>
            <template #cell(path)="{ item }">
              <file-download-link
                v-if="item.path"
                :api-url="`user_reports/${item.id}/download`"
                :filename="formatReportFilename(yearlyReport.report_key, item.user.full_name)"
                download-as-icon
              />
              <div v-else>Non généré</div>
            </template>
          </paginated-table>
        </div>
      </b-tab>
      <b-tab title="Générer pour un-e membre">
        <forms-validated-input
          :value="user ? user.id : null"
          type="relation"
          name="yearly-report-user"
          label="Membre"
          :query="{
            slug: 'users',
            value: 'id',
            text: 'full_name',
            details: 'id',
            details_label: 'user_id',
            params: {
              for: 'admin',
            },
          }"
          @relation="user = $event"
        ></forms-validated-input>
        <div class="mt-3 button-list align-items-center">
          <file-download-link
            v-if="user && year"
            :api-url="`/reports/yearly/${year}/income/${user.id}/generate`"
            :filename="formatReportFilename(year, user.full_name)"
            button-label="Générer le rapport"
            download-as-icon
          />
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<style scoped lang="scss">
.loading {
  opacity: 0.5;
}
.properties {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}
.progress-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
.progress {
  width: 5rem;
}
.label {
  font-weight: bold;
}
.tab-content {
  padding-top: 1rem;
}
</style>
