<template>
  <loan-status-alert-container :loan="loan">
    <template #borrower>
      <p>
        Voir les
        <icon-button variant="ghost-secondary" size="inline" @click="$emit('jump', 'instructions')"
          >détails du véhicule</icon-button
        >
        pour son emplacement et les instructions de {{ loanableOwnerUser.name }}.
        <template v-if="loan.borrower_may_contribute && !loan.platform_tip">
          Considérez
          <icon-button
            variant="ghost-secondary"
            size="inline"
            @click="$emit('jump', 'contribution')"
            >contribuer</icon-button
          >
          pour que LocoMotion continue à rouler!
        </template>
      </p>

      <template v-if="loan.loanable.type === 'car'">
        <div class="font-weight-bold">Au début de l'emprunt</div>
        <ol class="mb-0">
          <li>
            <span v-if="loan.loanable.details.report">
              Prenez connaissance de l'état de l'auto et comparez avec la
              <safe-file :file="loan.loanable.details.report">fiche d'état de l'auto.</safe-file>
            </span>
            <span v-else> Prenez connaissance de l'état de l'auto. </span>
          </li>
          <template v-if="loan.requires_detailed_mileage">
            <li>
              <icon-button variant="ghost-secondary" size="inline" @click="$emit('jump', 'info')"
                >Entrez le KM</icon-button
              >
              au compteur du tableau de bord.
            </li>
            <li>
              Ajoutez une photo du tableau de bord ou inscrivez le KM dans le carnet de bord qui se
              trouve dans l'auto.
            </li>
          </template>
        </ol>
      </template>
    </template>
    <template #owner>
      <template v-if="loan.loanable.type === 'car'">
        <div class="font-weight-bold">Au début de l'emprunt</div>
        <ol class="mb-0">
          <li>
            <span v-if="loan.loanable.details.report">
              Assurez-vous que la
              <safe-file :file="loan.loanable.details.report">fiche d'état de l'auto</safe-file>
              est à jour. Vous pouvez la mettre à jour
              <router-link :to="`/profile/loanables/${loan.loanable.id}#report-section`">
                ici</router-link
              >.
            </span>
            <template v-else>
              <span>
                Prenez connaissance de l'état de votre véhicule et
                <router-link :to="`/profile/loanables/${loan.loanable.id}#report-section`">
                  ajoutez la fiche d'état ici.
                </router-link>
              </span>
            </template>
          </li>
          <template v-if="loan.requires_detailed_mileage">
            <li>
              <icon-button variant="ghost-secondary" size="inline" @click="$emit('jump', 'info')"
                >Entrez le KM</icon-button
              >
              au compteur du tableau de bord.
            </li>
            <li>
              Ajoutez une photo du tableau de bord ou inscrivez le KM dans le carnet de bord qui se
              trouve dans l'auto.
            </li>
          </template>
        </ol>
      </template>
      <p v-else>En attente du début de l'emprunt.</p>
    </template>

    <template #admin>
      <p>En attente du début de l'emprunt.</p>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import IconButton from "@/components/shared/IconButton.vue";
import SafeFile from "@/components/shared/SafeFile.vue";
import { getOwner, isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isBorrower } from "@/helpers/permissions/loans";

export default {
  name: "LoanConfirmed",
  components: {
    IconButton,
    LoanStatusAlertContainer,
    SafeFile,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isBorrower() {
      return isBorrower(this.$store.state.user, this.loan);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.$store.state.user, this.loan.loanable);
    },
    loanableOwnerUser() {
      return getOwner(this.loan.loanable);
    },
  },
};
</script>

<style lang="scss"></style>
