<script>
import AdminListActions from "@/components/Admin/ListActions.vue";
import { getOwner } from "@/helpers/permissions/loanables";

export default {
  name: "LoanableListActions",
  components: { AdminListActions },
  props: {
    loanable: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      restoreParams: {
        restore_availability: false,
      },
    };
  },
  computed: {
    ownerUser() {
      return getOwner(this.loanable);
    },
  },
};
</script>

<template>
  <admin-list-actions
    v-if="!ownerUser || !ownerUser.deleted_at"
    slug="loanables"
    :item-name="loanable.name"
    :item="loanable"
    :restore-params="restoreParams"
    @change="$emit('changed')"
  >
    <template #destroy-question>
      <div>
        <strong>Êtes-vous sûr-e de vouloir archiver {{ loanable.name }}?</strong>
        <p class="mt-2 mb-0">
          Assurez-vous qu'il n'y a pas
          <router-link
            target="_blank"
            :to="`/admin/loans?loanable.id=${loanable.id}&status=in_process`"
            >d'emprunts en cours pour ce véhicule.
          </router-link>
        </p>
      </div>
    </template>
    <template #restore-question>
      <div>
        <strong>Êtes-vous sûr-e de vouloir restaurer {{ loanable.name }}?</strong>
        <b-form-checkbox v-model="restoreParams.restore_availability" class="mt-3">
          Restaurer les disponibilités?
        </b-form-checkbox>
      </div>
    </template>
  </admin-list-actions>
  <div v-else>
    <!-- If user is deleted we do not want a dropdown to be shown. This empty div is to remove
      the default from paginated table. -->
  </div>
</template>

<style scoped lang="scss"></style>
