import RestModule from "../RestModule";

export default new RestModule("loans", {
  evaluatingPrice: false,
  exportFields: [
    "id",
    "status",
    "departure_at",
    "duration_in_minutes",
    "estimated_distance",
    "actual_distance",
    "alternative_to",
    "alternative_to_other",
    "borrower_user.id",
    "borrower_user.name",
    "borrower_user.last_name",
    "loanable.id",
    "expenses_amount",
    "calendar_days",
    "canceled_at",
  ],
});
