<template>
  <div v-if="routeDataLoaded">
    <template v-if="loanable">
      <vue-headful :title="fullTitle" />

      <b-row class="mb-4">
        <b-col>
          <h1 v-if="loanable.name">{{ loanable.name }}</h1>
          <h1 v-else>
            <em>{{ $tc("loanable", 1) | capitalize }}</em>
          </h1>
        </b-col>
      </b-row>
    </template>

    <b-card v-if="loanable && !loanable.id && !loanableOwnerUser" class="bordered-card">
      <forms-validated-input
        type="relation"
        name="owner_user_id"
        :query="ownerUserQuery"
        :value="loanable.owner_user.id"
        :object-value="loanableOwnerUser"
        :rules="{ required: true }"
        label="Propriétaire"
        mode="eager"
        @relation="setLoanableOwnerUser"
      />
    </b-card>

    <loanable-form v-show="loanable && loanableOwnerUser" :id="id" />
  </div>
  <layout-loading v-else />
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import LoanableForm from "@/components/Loanable/LoanableForm.vue";

import { capitalize } from "@/helpers/filters";
import { getOwner } from "@/helpers/permissions/loanables";

import locales from "@/locales";

import DataRouteGuards from "@/mixins/DataRouteGuards";

export default {
  name: "AdminLoanable",
  components: {
    LoanableForm,
    FormsValidatedInput,
  },
  mixins: [DataRouteGuards],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ownerUserQuery: {
        slug: "users",
        value: "id",
        text: "full_name",
        details: "email",
        params: {
          fields:
            "id,full_name,communities.id,communities.name,available_loanable_types,communities.area",
        },
      },
    };
  },
  computed: {
    loanable() {
      return this.$store.state.loanables.item;
    },
    loanableOwnerUser() {
      // If loanable is not created yet, owner user can be in the loanable.owner_user field.
      if (!this.loanable.id && this.loanable.owner_user.id) {
        return this.loanable.owner_user;
      }

      return getOwner(this.loanable);
    },
    fullTitle() {
      const parts = [
        "LocoMotion",
        capitalize(this.$i18n.t("titles.admin")),
        capitalize(this.$i18n.tc("loanable", 2)),
      ];

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    pageTitle() {
      return this.loanable.name || capitalize(this.$i18n.tc("loanable", 1));
    },
  },
  methods: {
    async setLoanableOwnerUser(user) {
      this.$store.commit("loanables/patchItem", {
        owner_user: user,
      });
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loanables,
        ...locales.en.forms,
        titles: locales.en.titles,
      },
      fr: {
        ...locales.fr.loanables,
        ...locales.fr.forms,
        titles: locales.fr.titles,
      },
    },
  },
};
</script>

<style lang="scss"></style>
